<script>
import Layout from "../../layouts/auth";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

/**
 * Reset component
 */
export default {
  page: {
    title: "Resetar senha",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      token: "",
      password: "",
      confirmation: "",
      tryingToReset: false,
      authError: null,
      isAuthError: false,
      authSuccess: null,
      isAuthSuccess: false
    };
  },
  mounted() {
    this.token = this.$route.query.token;
  },
  methods: {
    ...authMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToReset() {
      this.tryingToReset = true;
      // Reset the authError if it existed.
      this.authError = null;
      return (
        this.resetPassword({
          password: this.password,
          password_confirmation: this.confirmation,
          token: this.token
        })
        // eslint-disable-next-line no-unused-vars
          .then(user => {
            this.tryingToReset = false;
            this.authSuccess = "Senha alterada com sucesse. Vá para tela de login para acessar o Painel de Parceiros.";
            this.isAuthSuccess = true;
          })
          .catch(error => {
            this.tryingToReset = false;
            this.authError = error ? error : "";
            this.isAuthError = true;
          })
      );
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">
          <div style="background-color: rgba(85, 110, 230, 0.15)">
            <div class="row">
              <div class="col-6">
                <div class="text-primary p-4">
                  <h5 class="text-primary">
                    Bem-vindo!
                  </h5>
                  <p>Insira a sua nova senha para atualizar as suas credenciais de acesso.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img
                  src="@/assets/images/partners-img.png"
                  alt
                  class="img-fluid"
                >
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div>
              <router-link
                tag="a"
                to="/"
              >
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img
                      src="@/assets/images/logo-dark.svg"
                      alt
                      width="48"
                    >
                  </span>
                </div>
              </router-link>
            </div>
            
            <b-alert
              v-model="isAuthError"
              variant="danger"
              class="mt-3"
              dismissible
            >
              {{ authError }}
            </b-alert>
  
            <b-alert
              v-model="isAuthSuccess"
              variant="success"
              class="mt-3"
              dismissible
            >
              {{ authSuccess }}
            </b-alert>
  
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form
                @submit.stop.prevent="handleSubmit(tryToReset)"
                class="p-2"
              >
                <validation-provider
                  name="Nova senha"
                  rules="required:true|min:8|confirmed:confirmation"
                  v-slot="errors"
                >
                  <b-form-group
                    id="input-group-1"
                    label="Nova senha"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="password"
                      type="password"
                      placeholder=""
                    />
                    <b-form-invalid-feedback>{{ errors.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
  
                <validation-provider
                  v-slot="errors"
                  vid="confirmation"
                >
                  <b-form-group
                    id="input-group-2"
                    label="Confirme a nova senha"
                    label-for="input-2"
                  >
                    <b-form-input
                      id="input-2"
                      v-model="confirmation"
                      type="password"
                      placeholder=""
                    />
                    <b-form-invalid-feedback>{{ errors.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                
                <div class="mt-3">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="btn-block"
                  >
                    Salvar
                  </b-button>
                </div>
                <div class="mt-4 text-center">
                  <router-link
                    tag="a"
                    to="/login"
                    class="text-muted"
                  >
                    <i class="mdi mdi-login mr-1" /> Ir para o login
                  </router-link>
                </div>
              </b-form>
            </validation-observer>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
        
        <div class="mt-5 text-center">
          <p>
            Não possui cadastro?
            <a
              href="https://go.huggy.io/parceiros"
              class="font-weight-medium text-primary"
              target="_blank"
            >Criar uma conta</a>
          </p>
          <p>
            © {{ new Date().getFullYear() }} Huggy.
          </p>
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
